<template>
  <div class="view">
    <ul class="view_tab">
      <li class="on"><router-link to="">일반</router-link></li>
      <li><router-link to="" class="none_text"></router-link></li>
    </ul>
    <div class="view_text">
      <div class="box">
        <div class="box_top">
          <div>리뷰어 신뢰도 지수</div>
          <div>전체 평균 {{ trustScoreCp }}</div>
        </div>
        <ul class="box_bottom">
          <li v-for="list in detail" :key="list.main_menu_seq">
            {{ list.title }} {{ rammFloat(list.trust_score) }}
          </li>
        </ul>
      </div>
      <div class="box">
        <div class="box_top">
          <div>리뷰어 리뷰수</div>
          <div>총 {{ reviewCntCp }}개</div>
        </div>
        <ul class="box_bottom">
          <li v-for="list in detail" :key="list.main_menu_seq">
            {{ list.title }} {{ list.cnt }}
          </li>
        </ul>
      </div>
      <div class="box">
        <div class="box_top">
          <div>리뷰를 통한 구매건수</div>
          <div>총 {{ saleCntCp }}</div>
        </div>
        <ul class="box_bottom">
          <li v-for="list in detail" :key="list.main_menu_seq">
            {{ list.title }} {{ list.sale_cnt }}
          </li>
        </ul>
      </div>
    </div>
    <ul class="view_btn">
      <li>
        <router-link to="" @click.native="movePage"
          ><img
            src="@/assets/images/sub/reviewer_rank_ico_01.png"
            alt=""
          />[리뷰어 홈] 가기</router-link
        >
      </li>
      <!-- <li>
                <a href="#"
                  ><img
                    src="@/assets/images/sub/reviewer_rank_ico_02.png"
                    alt=""
                  />리뷰거래 제안하기
                </a>
              </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Array,
      default: () => [],
      required: true,
    },
    menu: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: Array,
      default: () => [],
    },
    tapName: {
      type: String,
      default: "reviewer",
    },
    categoryCode: {
      type: String,
      default: "",
    },
    detailCode: {
      type: Number,
      default: 0,
    },
    reviewerSeq: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reaTime: process.env.VUE_APP_NEWS_REAL_TIME,
    };
  },
  computed: {
    trustScoreCp() {
      let score = 0;
      if (this.detail.length > 0) {
        this.detail.map((list) => {
          score += Number(list.trust_score);
        });
        score = score / this.detail.length;
      }
      return this.$rammFloat(score);
    },
    reviewCntCp() {
      let cnt = 0;
      this.detail.map((list) => {
        cnt += Number(list.cnt);
      });
      return this.$won(cnt);
    },
    saleCntCp() {
      let cnt = 0;
      this.detail.map((list) => {
        cnt += Number(list.sale_cnt);
      });
      return this.$won(cnt);
    },
  },
  methods: {
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    movePage() {
      if (parseInt(this.menu) === 1) {
        if (this.tapName === "reviewer") {
          this.$router.push(
            `/review/reviewer_goods/${this.reviewerSeq}?menu=${this.menu}`
          );
        } else if (this.tapName === "part") {
          const category_code = this.category.filter(
            (cate) => cate.category_code === this.categoryCode
          );
          this.$router.push(
            `/review/reviewer_goods/${this.reviewerSeq}/${category_code[0].id}?menu=${this.menu}`
          );
        }
      } else if (parseInt(this.menu) === 3 && parseInt(this.reviewerSeq) > 0) {
        if (this.tapName === "reviewer") {
          this.$router.push(
            `/review/reviewer_news/${this.reviewerSeq}?menu=${this.menu}`
          );
        } else if (this.tapName === "part") {
          let categoryCode = !this.categoryCode
            ? this.category[0].seq
            : this.categoryCode;
          let detailCode = !this.detailCode
            ? this.category[0].level2_seq
            : this.detailCode;
          if (parseInt(categoryCode) == this.reaTime) {
            this.$router.push(
              `/review/reviewer_news/${this.reviewerSeq}/${categoryCode}?menu=${this.menu}`
            );
          } else {
            this.$router.push(
              `/review/reviewer_news/${this.reviewerSeq}/${categoryCode}?category=${detailCode}&menu=${this.menu}`
            );
          }
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.none_text {
  display: inline-block;
  width: 100%;
}
</style>
