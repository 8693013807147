<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      리뷰어 순위
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="keyword"
            placeholder="리뷰어를 검색하세요."
            @keypress.enter="getDataSearch"
          />
        </div>
        <router-link to="" @click.native="getDataSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <ReviewMainMenu
      v-if="mainMenuList.length > 0"
      :reviewMenuList="mainMenuList"
      :id="getId"
      ref="reviewMainMenu"
      @getMenu="getMenu"
    />
    <!---->
    <div class="rn_ms_section">
      <!---->
      <div class="rn_ms_box_8">
        <!---->
        <ul class="sect_tab">
          <li class="on">
            <router-link to="" @click.native="setTapMenu('reviewer')"
              >리뷰어 순위</router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="setTapMenu('part')"
              >분야별 리뷰어 순위
            </router-link>
          </li>
        </ul>
        <!--분야별 리뷰어 순위-->
        <RankGoodsCategory
          v-if="tapName === 'part' && parseInt(menu) === 1"
          :category="category"
          @getGoodsCategory="getGoodsCategory"
        />
        <RankNewsCategory
          v-if="tapName === 'part' && parseInt(menu) === 3"
          :category="category"
          @getCategory="getCategory"
        />
        <!-- -->
        <div class="sect_list">
          <div class="top">
            <div>순위</div>
            <div>리뷰어</div>
            <div>신뢰도 지수</div>
            <!--<div>이 분야 신뢰도 지수</div>-->
            <div>&nbsp;</div>
          </div>
          <template v-for="(list, index) in listArray">
            <div
              class="list common"
              :key="list.seq"
              @click="getRankDetail(list.seq, $event)"
            >
              <div>{{ index + 1 }}</div>
              <div>{{ list.reviewer_nick }}</div>
              <div>{{ rammFloat(list.trust_score) }}</div>
              <div>
                <router-link to="">
                  <img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                    class="down_icon"
                  />
                </router-link>
              </div>
            </div>
            <RankDetail
              :key="`${list.seq}_detail`"
              :id="`detail_${list.seq}`"
              :detail="detail"
              :reviewerSeq="parseInt(list.member_seq)"
              :menu="parseInt(menu)"
              :data="list"
              :tapName="tapName"
              :category="category"
              :categoryCode="categoryCode"
              :detailCode="parseInt(detailCode)"
              class="detail"
              style="display: none"
            />
          </template>
          <div class="list common no_data" v-if="listArray.length <= 0">
            <div>데이터가 없습니다.</div>
          </div>
        </div>
        <!---->
        <div class="rn_more" v-if="parseInt(totalPage) > parseInt(page)">
          <router-link to="" @click.native="getRammRank()"
            >{{ parseInt(limit) }}개 더보기<img
              src="@/assets/images/sub/my_down_ico.png"
              alt=""
          /></router-link>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewMainMenu from "@/components/review/state/ReviewMainMenu";
import RankDetail from "@/components/rank/RankDetail";
import RankGoodsCategory from "@/components/rank/RankGoodsCategory";
import RankNewsCategory from "@/components/rank/RankNewsCategory";
export default {
  data() {
    return {
      keyword: "",
      menu: parseInt(this.$route.params.main_seq) || 1,
      tapName: "reviewer",
      page: 0,
      categoryCode: null,
      detailCode: null,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      rank: true,
    });
    this.getMainMenu();
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("rank", [
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
      "detail",
      "category",
    ]),
    getId() {
      const id = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(this.menu)
      );
      return id;
    },
  },
  methods: {
    setTapMenu(type) {
      [...document.querySelectorAll(".detail")].map(
        (view) => (view.style.display = "none")
      );

      [...document.querySelectorAll(".sect_tab li")].map((li) =>
        li.classList.remove("on")
      );
      if (type === "reviewer") {
        [...document.querySelectorAll(".sect_tab li")][0].classList.add("on");
        this.categoryCode = null;
        this.tapName = "reviewer";
      } else {
        [...document.querySelectorAll(".sect_tab li")][1].classList.add("on");
        this.categoryCode = null;
        this.tapName = "part";
      }
      this.getRammRank(true);
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getRammRank(true);
    },
    getDataSearch() {
      // if (this.keyword.replace(/\s/g, "").length <= 0) {
      //   this.$toast.default("검색어를 입력해 주세요.");
      //   return false;
      // }
      this.getRammRank(true);
    },
    async getRammRank(reset) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("rank/getRammRank", {
        main_menu: this.menu,
        page: this.page,
        keyword: this.keyword,
        type: this.tapName,
        category_code: this.categoryCode,
        detail_code: this.detailCode,
        reset,
      });
      if (this.tapName === "part" && parseInt(this.menu) === 1) {
        document
          .querySelector(".swiper-slide.reviewer.swiper-slide-active")
          .classList.add("on");
      }
      this.page++;
    },
    async getRankDetail(seq, event) {
      const imgEl = event.target.closest(".list").querySelector("img");
      const id = `detail_${seq}`;
      const detail = document.querySelector(`#${id}`);

      if (detail.style.display === "none") {
        await this.$store.dispatch("rank/getRankDetail", { seq });
      }

      [...document.querySelectorAll(".list")].map((list) => {
        if (list.querySelector("img") !== imgEl) {
          list.querySelector("img").style.transform = "";
        }
      });
      [...document.querySelectorAll(".view.detail")].map((detail) => {
        if (detail.id !== id) {
          detail.style.display = "none";
        }
      });

      imgEl.style = !imgEl.style.transform ? "transform: rotate(180deg)" : "";
      detail.style.display = detail.style.display === "none" ? "block" : "none";
    },
    rammFloat(num) {
      return this.$rammFloat(num);
    },
    getMenu(num) {
      this.menu = num;
      this.keyword = "";
      this.categoryCode = null;
      this.tapName = "reviewer";
      this.setTapMenu(this.tapName);
    },
    getGoodsCategory(category_code) {
      this.categoryCode = category_code;
      this.tapName = "part";
      this.getRammRank(true);
    },
    getCategory(category_code, detailCode) {
      this.categoryCode = category_code;
      this.detailCode = detailCode;
      this.tapName = "part";
      this.getRammRank(true);
    },
  },
  components: {
    ReviewMainMenu,
    RankDetail,
    RankGoodsCategory,
    RankNewsCategory,
  },
};
</script>

<style></style>
