<template>
  <div class="sect_n_cate">
    <div class="n_cate_top">
      <div
        v-for="(level1, index) in level1Menu"
        :key="level1.seq"
        @click="showMenu(level1.seq, $event)"
        :class="{ on: index === 0 }"
      >
        <router-link to="">{{ level1.title }}</router-link>
      </div>
    </div>
    <template v-for="menu in level1Menu">
      <ul
        class="n_cate_bottom depth_5 n_cate_num_1"
        v-if="parseInt(menu.seq) !== 48"
        :key="menu.seq"
        :id="`menu_${menu.seq}`"
        :style="parseInt(menu.seq) !== 15 ? 'display:none' : ''"
      >
        <li
          v-for="(level2, idx) in checkMenuLevel2(menu.seq)"
          :class="{ on: parseInt(idx) === 0 }"
          :key="level2.level2_seq"
          :data-option2="level2.level2_seq"
          :data-idx="idx"
        >
          <router-link to="" @click.native="getData(menu.seq, $event)">{{
            level2.level2_title
          }}</router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import _sortBy from "lodash/sortBy";
export default {
  data() {
    return {
      level1Seq: 0,
    };
  },
  props: {
    category: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    level1Menu() {
      let level1Arr = [];
      let tmp = 0;
      this.category.map((level1) => {
        if (tmp !== level1.seq) {
          level1Arr.push(level1);
        }
        tmp = level1.seq;
      });
      return level1Arr;
    },
  },
  methods: {
    showMenu(seq, event) {
      [...document.querySelectorAll(".n_cate_top div")].map((main) =>
        main.classList.remove("on")
      );
      event.target.closest("div").classList.add("on");
      [...document.querySelectorAll(".n_cate_num_1")].map(
        (menu) => (menu.style.display = "none")
      );
      if (parseInt(seq) !== 48) {
        document.querySelector(`#menu_${seq}`).style.display = "block";
      }
      [...document.querySelectorAll(`#menu_${seq} li`)].map((li) =>
        li.classList.remove("on")
      );
      //속보일때는 0
      let option2 = 0;
      if (parseInt(seq) !== 48) {
        document.querySelectorAll(`#menu_${seq} li`)[0].classList.add("on");
        option2 = document.querySelectorAll(`#menu_${seq} li`)[0].dataset
          .option2;
      }

      this.$emit("getCategory", seq, option2);
    },
    getData(mainSeq, event) {
      [...event.target.closest("ul").querySelectorAll("li")].map((li) =>
        li.classList.remove("on")
      );
      event.target.closest("li").classList.add("on");
      const seq = event.target.closest("li").dataset.option2;
      this.$emit("getCategory", mainSeq, seq);
    },
    checkMenuLevel2(seq) {
      const data = this.category.filter(
        (cate) => parseInt(cate.seq) === parseInt(seq)
      );
      data.map((data) => {
        data.child_sort = parseInt(data.child_sort);
      });
      return _sortBy(data, "child_sort");
    },
  },
};
</script>

<style></style>
